.logo {
    width: 10em;
}

ion-grid{
    height: 100%;
}
ion-row{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    width: 100%;
}

ion-button {
    --background: linear-gradient(120deg, #155799, #159957);
}

/* Specifically for the sponsors and organizers */
ion-card-content > ion-grid {
    max-width: 50em;
    margin: auto; /* Works outside of card environment, but not inside ion-card*/
  }