/* File extended from file of same name in edapt-app repository. */

ion-card-title,
ion-card-subtitle,
ion-title {
  text-align: center;
}

img {
  display: block;
  margin: auto;
}

p {
  max-width: 50em;
  margin: auto; /* Works outside of card environment, but not inside ion-card*/
}

ul {
  max-width: 50em;
  margin: auto;
}

ion-card-content > ion-list,
.card-content-md > .list-md,
.card-content-ios > .list-ios {
  max-width: 50em;
  margin: auto;
}

.fancy-p {
  /*For fancy paragraphs*/
  text-indent: 2em;
}
.fancy-p::first-letter {
  font-size: 150%;
  /* color: #8a2be2; */
  /* font-weight: bold; */
}

/* I think this overrides the more local rules from ionic for p's inside cards.  */
/* Centered buttons should be placed inside p's and then set to text-align:center */
ion-card-content > p,
.card-content-md p,
.card-content-ios p {
  /*Selects only immediate children. p's inside grids, etc. are unaffected.*/
  margin: auto;
  margin-bottom: 1em;
}

ion-card-content > h1,
.card-content-md h1,
.card-content-ios h1,
ion-card-content > h2,
.card-content-md h2,
.card-content-ios h2 {
  /*Selects only immediate children. p's inside grids, etc. are unaffected.*/
  margin: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: center;
}

ion-item {
  margin-bottom: 0.5em;
}

ion-col {
  margin-bottom: 2em;
  min-width: 18em;
}